





























































































































































































































































































































































































































































import Component, { mixins } from 'vue-class-component';

import WidgetMixins from '../../../mixins/WidgetMixins';
import DialogMixins from '../../../mixins/DialogMixins';
import { Getter, namespace } from 'vuex-class';
import {
  IActionDialog,
  IAPIKeys,
  IContact,
  IPaginate,
  IShareCredit,
  IUser,
} from '@/types/types';
import { loadView, loadWidget } from '@/utils/helpers';
import { Watch } from 'vue-property-decorator';
import { debounce } from 'lodash';

const contactModule = namespace('contact');
const usersModule = namespace('users');
const apiKeyModule = namespace('apiKeys');
@Component({
  name: 'Index',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSubAccountDialog: loadView('pages/settings/dialogs/AddSubAccountDialog'),
    ShareCreditDialog: loadView('pages/settings/dialogs/ShareCreditDialog'),
    GenerateAPIKeyDialog: loadView(
      'pages/settings/dialogs/GenerateAPIKeyDialog'
    ),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
  },
  filters: {},
})
export default class Index extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getUseIntlRoute') useIntlRoute!: boolean;
  @Getter('getResetFormState') resetFormState!: boolean;
  @usersModule.Getter('getPagination') paginate!: Pick<
    IPaginate,
    'page' | 'total' | 'itemsPerPage'
  > & { limit: number };

  @contactModule.Getter('getAllContacts') contacts!: ReadonlyArray<IContact>;
  @usersModule.Getter('getAllSubAccounts') subAccounts!: ReadonlyArray<IUser>;
  @apiKeyModule.Getter('getAPIKeys') apiKeyDetails!: IAPIKeys;

  options: IPaginate = {
    itemsPerPage: 30,
    page: 1,
    total: 0,
  };
  sort: string | null = 'name,ASC';

  search = '';
  isSearching = false;

  details: Pick<IUser, 'id' | 'mobile' | 'name' | 'isActive'> = {
    id: '',
    mobile: '',
    name: '',
    isActive: false,
  };
  actionDialog: Partial<IActionDialog> = {
    actionMessage: '',
    actionText: '',
    actionImage: '',
  };
  action = '';

  headers: Array<{
    text: string;
    value: string;
    align?: string;
    sortable?: boolean;
  }> = [
    {
      text: 'Name',
      value: 'name',
    },
    {
      text: 'Email',
      value: 'email',
    },
    {
      text: 'Phone',
      value: 'phone',
      sortable: false,
    },
    {
      text: 'Local Acc.',
      value: 'credit',
      sortable: false,
    },
    {
      text: 'Intl. Acc.',
      value: 'intlCredit',
      sortable: false,
    },
    {
      text: 'Acc. Status',
      value: 'isActive',
      sortable: false,
    },
    {
      text: 'Actions',
      align: 'center',
      value: 'actions',
      sortable: false,
    },
  ];

  searchText(term: string): void {
    this.$store.dispatch('users/list', {
      query: `?page=${this.paginate.page}&size=${
        this.paginate.itemsPerPage
      }&searchTerm=${term}${this.sort ? '&sort=' + this.sort : ''}`,
    });
  }

  handleOnSearchTextChange = debounce(this.searchText, 1000);

  @Watch('search')
  onSearchTermChange(term: string): void {
    this.handleOnSearchTextChange(term);
  }

  @Watch('options')
  onOptionsChange(payload: IPaginate): void {
    if (
      payload.sortBy &&
      payload.sortBy.length > 0 &&
      payload.sortDesc &&
      payload.sortDesc.length > 0
    ) {
      console.log(payload.sortBy);
      this.sort = `${payload.sortBy[0]},${
        payload.sortDesc[0] ? 'DESC' : 'ASC'
      }`;
    }
    this.$store.dispatch('users/list', {
      query: `?page=${payload.page}&size=${payload.itemsPerPage}${
        this.sort ? '&sort=' + this.sort : ''
      }`,
    });
  }

  @Watch('$route.query')
  onQueryChange(payload: { accId: string }): void {
    if (payload.accId) {
      this.open('edit', payload.accId, 'apiKeys/apiKeyByCustomerID');
    } else {
      this.close({ idx: 'edit', state: false });
    }
  }

  addSubAccount(
    payload: IUser & { disableSubAccountLogIn?: boolean; senderId: string }
  ): void {
    this.$store.dispatch('users/setUpSubAccount', {
      ...payload,
      acceptPrivacyPolicy: true,
    });
  }

  addShareCredit(payload: {
    credits: ReadonlyArray<IShareCredit>;
    localTotalCreditAmount: number;
    intlTotalCreditAmount: number;
  }): void {
    const { localTotalCreditAmount, intlTotalCreditAmount } = payload;
    const filteredAccounts = payload.credits.filter(
      (acc) => acc.intlTotalCredit !== null || acc.localTotalCredit !== null
    );

    this.$store.dispatch('users/shareCreditAcrossSubAccount', {
      credits: filteredAccounts,
      localTotalCreditAmount,
      intlTotalCreditAmount,
    });
  }

  openDeleteDialog(item: IUser & { isActive: boolean }, action: string): void {
    this.open('delete');
    this.details = item;
    this.action = action;
    if (action === 'activate') {
      this.actionDialog = {
        actionText: item.isActive ? 'Deactivate' : 'Activate',
        actionImage: require('@/assets/images/settings.png'),
        actionMessage:
          'Once you perform this operation, the admin of this sub account cannot use it',
      };
    } else {
      this.actionDialog = {
        actionMessage:
          'Once you delete this sub account, this operation cannot be undone.',
      };
    }
  }

  deleteSubAccount(): void {
    if (!this.details?.id) {
      this.$store.dispatch(
        'snackBarMessage',
        `Unable to communicate with our backend service to perform this action, try again later`,
        { root: true }
      );
      this.$store.dispatch('snackBarVisibility', true, { root: true });
      return;
    }
    if (this.action === 'activate') {
      this.$store.dispatch('users/changeSubAccountStatus', {
        id: this.details?.id,
        isActive: !this.details?.isActive,
      });
    } else {
      this.$store.dispatch('users/deleteSubAccount', { id: this.details?.id });
    }
  }

  copyContact(text: string): void {
    this.$copyText(text).then(
      () => {
        this.$store.dispatch('snackBarMessage', `Copied`, { root: true });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      },
      () => {
        this.$store.dispatch('snackBarMessage', `Unable to copy contact`, {
          root: true,
        });
        this.$store.dispatch('snackBarVisibility', true, { root: true });
      }
    );
  }

  apiKeyDialog(id: string | number, action: string): void {
    if (action === 'open') {
      this.$router.push({
        query: {
          accId: id as string,
        },
      });
    } else {
      this.close({ idx: 'edit', state: false });
      this.$router.push({
        query: {
          accId: undefined,
        },
      });
    }
  }

  generateAPIKey(): void {
    this.$store.dispatch('apiKeys/generateKey', {
      name: 'APP',
      customerId: this.apiKeyDetails?.userId ?? this.$route.query?.accId,
      key: 'edit',
    });
  }

  revokeAPIKey(id: number): void {
    this.$store.dispatch('apiKeys/revokeKeys', {
      id,
      customerId: this.apiKeyDetails?.userId ?? this.$route.query?.accId,
      key: 'edit',
    });
  }

  copyCredentials(payload: { credential: string; field?: string }): void {
    navigator.clipboard.writeText(payload.credential).then(
      (message) => {
        console.log(message);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  created(): void {
    const query = `?page=1&size=15${this.sort ? '&sort=' + this.sort : ''}`;
    this.$store.dispatch('users/list', {
      query,
    });
    this.$store.dispatch('checkCreditBalance');
    if (this.$route.query?.accId) {
      this.open(
        'edit',
        this.$route.query.accId as string,
        'apiKeys/apiKeyByCustomerID'
      );
    }
  }
}
